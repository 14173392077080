const initialState = {
  sidebarShow: false,
};
const SidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case "selectSidebarShow":
      return { sidebarShow: action.payload };
    default:
      return state;
  }
};
export default SidebarReducer;
