import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css'

// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css'
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-quill/dist/quill.snow.css'

import '../src/assets/css/requiredFields.css'

import { CookiesProvider } from 'react-cookie'
import { ToastContainer } from 'react-toastify'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ForgotPassword = React.lazy(() =>
  import('./views/pages/login/ForgotPassword')
)
const ResetPassword = React.lazy(() =>
  import('./views/pages/login/ResetPassword')
)

// class App extends Component {
const App = props => {
  // render() {
  return (
    <CookiesProvider>
      <BrowserRouter basename='zoom'>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path='/login'
              name='Login Page'
              render={props => <Login {...props} />}
            />
            <Route
              exact
              path='/forgot-password'
              name='Forgot Password'
              render={props => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path='/reset-password'
              name='Reset Password'
              render={props => <ResetPassword {...props} />}
            />
            <Route
              exact
              path='/register'
              name='Register Page'
              render={props => <Register {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={props => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={props => <Page500 {...props} />}
            />
            <Route
              path='/'
              name='Home'
              render={props => <DefaultLayout {...props} />}
            />
          </Switch>
          <ToastContainer
            position='top-right'
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </React.Suspense>
      </BrowserRouter>
    </CookiesProvider>
  )
  // }
}

export default App
