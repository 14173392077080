const initialState = {
  permission: "",
};
const setPermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "permission":
      return action.payload;
    default:
      return state;
  }
};
export default setPermissionReducer;
